import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Control } from "react-hook-form";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Box, Button, IconButton } from "@mui/material";

import { UPDATE_USER_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidUser";
import { UPDATE_TEAM_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTeam";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  DetailType,
  TeamAndUserDetail,
} from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { UserFileDomain } from "@sellernote/_shared/src/types/forwarding/bid";
import { convertFileSize } from "@sellernote/_shared/src/utils/common/uploadFiles";
import AdminDetailDescription from "@sellernote/_shared-for-forwarding-admin/src/components/AdminDetailDescription";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import FileName from "../../../containers/FileName";
import UploadModal from "../../../containers/UploadModal";
import useSnackbar from "../../../hooks/useSnackbar";

function EtcInfo({
  detailData,
  control,
  isEditMode,
  type,
}: {
  detailData: TeamAndUserDetail;
  control: Control<UPDATE_TEAM_DETAIL_REQ | UPDATE_USER_DETAIL_REQ>;
  isEditMode: boolean;
  type: DetailType;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [uploadType, setUploadType] = useState("docTypeRC");

  const detailDataId = type === "user" ? detailData?.userId : detailData?.id;

  const handleUploadModalOpen = (uploadType: UserFileDomain) => {
    return () => {
      setUploadType(uploadType);
      setShowsUploadModal(true);
    };
  };

  return (
    <>
      <AdminDetailDescription
        title={"부가정보"}
        isEditMode={isEditMode}
        descriptionValueList={[
          {
            label: "대표자명",
            value: detailData.representativeName,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"representativeName"}
                control={control}
                defaultValue={detailData.representativeName}
              />
            ),
            gridSize: 4,
          },
          {
            label: "업종",
            value: detailData.bizClass,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"bizClass"}
                control={control}
                defaultValue={detailData.bizClass}
              />
            ),
            gridSize: 4,
          },
          {
            label: "업태",
            value: detailData.bizType,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"bizType"}
                control={control}
                defaultValue={detailData.bizType}
              />
            ),
            gridSize: 4,
          },
          {
            label: "대표 전화번호",
            value: detailData.representativePhone,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"representativePhone"}
                control={control}
                defaultValue={detailData.representativePhone}
              />
            ),
            gridSize: 4,
          },
          {
            label: "대표 이메일",
            value: (
              <>
                {detailData.representativeEmail}
                <CopyToClipboard
                  text={detailData.representativeEmail || ""}
                  onCopy={() => handleSnackbarOpen("복사되었습니다.")}
                >
                  <IconButton>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </CopyToClipboard>
              </>
            ),
            editComponent: (
              <>
                <TextFieldWithReactHookForm
                  name={"representativeEmail"}
                  control={control}
                  defaultValue={detailData.representativeEmail}
                />
              </>
            ),
            gridSize: 4,
          },
          {
            label: "대표 팩스번호",
            value: detailData.representativeFax,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"representativeFax"}
                control={control}
                defaultValue={detailData.representativeFax}
              />
            ),
            gridSize: 4,
          },
          {
            label: "주소",
            value: detailData.address,
            gridSize: 12,
            editComponent: (
              <TextFieldWithReactHookForm
                name={"address"}
                fullWidth
                control={control}
                defaultValue={detailData.address}
              />
            ),
          },
          {
            label: "법인등기부등본",
            value: (
              <Box>
                {detailData?.remittanceAttachments
                  ?.filter((v) => {
                    return v.domain === "docTypeRC";
                  })
                  .map((v) => {
                    return (
                      <FileName
                        key={v.key}
                        attachment={v}
                        dataType={"team"}
                        id={detailData.id}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("docTypeRC")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
          {
            label: "법인인감증명서",
            value: (
              <Box>
                {detailData?.remittanceAttachments
                  ?.filter((v) => {
                    return v.domain === "docTypeCC";
                  })
                  .map((v) => {
                    return (
                      <FileName
                        key={v.key}
                        attachment={v}
                        dataType={"team"}
                        id={detailData.id}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("docTypeCC")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
          {
            label: "주주명부",
            value: (
              <Box>
                {detailData?.remittanceAttachments
                  ?.filter((v) => {
                    return v.domain === "docTypeLS";
                  })
                  .map((v) => {
                    return (
                      <FileName
                        key={v.key}
                        attachment={v}
                        dataType={"team"}
                        id={detailData.id}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("docTypeLS")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
          {
            label: "위임장",
            value: (
              <Box>
                {detailData?.remittanceAttachments
                  ?.filter((v) => {
                    return v.domain === "docTypeCS";
                  })
                  .map((v) => {
                    return (
                      <FileName
                        key={v.key}
                        attachment={v}
                        dataType={"team"}
                        id={detailData.id}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("docTypeCS")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
          {
            label: "대표자신분증",
            value: (
              <Box>
                {detailData?.remittanceAttachments
                  ?.filter((v) => {
                    return v.domain === "docTypeRI";
                  })
                  .map((v) => {
                    return (
                      <FileName
                        key={v.key}
                        attachment={v}
                        dataType={"team"}
                        id={detailData.id}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("docTypeRI")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
          {
            label: "대리인신분증",
            value: (
              <Box>
                {detailData?.remittanceAttachments
                  ?.filter((v) => {
                    return v.domain === "docTypeAI";
                  })
                  .map((v) => {
                    return (
                      <FileName
                        key={v.key}
                        attachment={v}
                        dataType={"team"}
                        id={detailData.id}
                      />
                    );
                  })}

                <Button
                  disabled={APP_NAME !== "shipda-admin"}
                  onClick={handleUploadModalOpen("docTypeAI")}
                  variant="outlined"
                  size="small"
                >
                  등록
                </Button>
              </Box>
            ),
            gridSize: 4,
          },
        ].filter((v) => {
          if (type === "user") {
            return (
              v.label !== "법인등기부등본" &&
              v.label !== "법인인감증명서" &&
              v.label !== "주주명부" &&
              v.label !== "위임장" &&
              v.label !== "대리인신분증"
            );
          }
          return true;
        })}
      />

      {showsUploadModal && detailDataId && (
        <UploadModal
          setShowsUploadModal={setShowsUploadModal}
          showsUploadModal={showsUploadModal}
          type={uploadType}
          id={detailDataId}
          dataType={type}
          teamId={type === "team" ? detailData.id : detailData.teamId}
          isPermission={false}
          isMultiple={false}
          acceptedFileTypes={[
            "jpeg",
            "jpg",
            "png",
            "bmp",
            "gif",
            "pdf",
            "xlsx",
            "xls",
          ]}
          allowedFileSize={convertFileSize({
            size: 8,
            fromUnit: "MB",
            toUnit: "B",
          })}
        />
      )}
    </>
  );
}

export default EtcInfo;
